import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configImages from '../../../../config/configImages';
import MenuBar from '../../../Layout/sidebar/menubar';
import fetchMethodRequest from '../../../../config/service';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../../config/configMessages';
import { TypingLoader } from './typingLoader';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

const Search = () => {
  const [messages, setMessages] = useState([]);
  let messagesRef = useRef([])
  const [input, setInput] = useState('');
  const chatHistoryRef = useRef(null);
  const menuRef = useRef(null);
  const noProfileImage = configImages.defaultImg;
  const {chatId} = useParams();
  const location = useLocation();
  let filterRef = useRef({"page":1,"limit":20,"sortfield":"created","direction" : "desc","criteria":[{"key":"chatId","value": chatId,"type":"eq"}]})
  const [totalCount,setTotalCount] = useState(0)
  let newMessageRef = useRef();
  let previousScrollHeightRef = useRef();
  const [disableButton , setDisableButton] = useState(false)


  useEffect(() => {
    let obj = Object.assign(filterRef.current,{"page" : 1,"criteria":[{"key":"chatId","value": chatId,"type":"eq"}]});
    filterRef.current = obj
    setMessages([])
    messagesRef.current = [];
    setTimeout(() => {
      insertMessages(obj,chatId)
    }, 200);
  },[location])

  useEffect(() => {
    if (chatHistoryRef.current && (filterRef.current?.page === 1 || newMessageRef.current)) {
      newMessageRef.current = false
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    } else {
      if(chatHistoryRef?.current){
        chatHistoryRef.current.scrollTop = chatHistoryRef?.current.scrollHeight - previousScrollHeightRef.current;
      }

    }
  }, [messages]);

  const createChat = () => {
    if(menuRef?.current) {
      menuRef.current?.createChat(true)
    }
  }

  const handleSend = () => {
    if(!input || disableButton) {
      return;
    }
    let url;
    if(chatId){
      url = `files/fileRead?chatId=${chatId}`
    } else {
      url = 'files/fileRead'
    }
    setDisableButton(true);
    let body = { "query": input?.trim() }
    const userMessage = { question: input, answer: 'loader' };
    setInput('');
    setMessages([...messages, userMessage]);
    fetchMethodRequest('POST', url,  body )
      .then((res) => {
        if( chatId && res?.messages?.chatId !== chatId) return
        if (res && res.messages) {
          newMessageRef.current = true;
          let updatedMessages = messages
          if(messages[messages.length - 1]?.answer === 'loader') {
            updatedMessages = messages.slice(0,-1);
            updatedMessages.push(res.messages)
          }
          else {
            updatedMessages.push(res.messages)
          }
          setMessages([...updatedMessages]);
          if(!chatId){
            createChat()
          }
        }else{
          showToasterMessage(configMessages.deafultErrorMessage)

        }
        setDisableButton(false)
      }).catch((err) => {
        console.error("Fetch error:", err);
        showToasterMessage(configMessages.deafultErrorMessage, err);
      });
  };

  function insertMessages(defaultFilter) {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(!chatId) return;
    fetchMethodRequest('GET',`messages?filter=${JSON.stringify(finalFilter)}`).then(async (resp) => {
      if (resp && resp.messages?.length > 0) {
        if(resp.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp.pagination?.page})
          // setFilter(obj);
          filterRef.current = obj
        }
        if(resp.pagination?.totalCount) {
          setTotalCount(resp.pagination?.totalCount)
        }
        // setMessages(resp.messages);
        if(finalFilter.page  === 1) {
          messagesRef.current = resp.messages?.reverse()
          setMessages(messagesRef.current);
        } else {
          previousScrollHeightRef.current = chatHistoryRef.current?.scrollHeight;
          messagesRef.current = [...resp.messages?.reverse(),...messagesRef.current]
          setMessages(messagesRef.current);
        }
      }
    })
  };

  const debouncedGetMessages = useCallback(
    _.debounce(() => {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      insertMessages(currentFilter)
    }, 50),
    []
  );

  const onScrollTop = (e) => {
    const bottom = e.target.scrollTop == 0;
    if(bottom  && messages.length < totalCount) {
      debouncedGetMessages()

    }
  }

  return (
    <div className='summary-search-containar' style={{ backgroundColor : '#fff'}}>
    <div className='d-flex align-items-start'>
      <MenuBar setMessages={setMessages} chatId={chatId} ref={menuRef}/>
      <div className='d-flex justify-content-center align-items-center chat-bot-main'>
        <div className="chatbot-container">
          {
            chatId || messages?.length > 0? 
            <> 
            <div className="chatbot-messages" ref={chatHistoryRef} onScroll={onScrollTop}>
              {messages.map((message, index) => (
                <>
                  <div className='d-flex justify-content-end align-items-center mb-3' key={'question'+index}>
                    <div className={'message user'}>{message?.question}</div>
                    <div className='icon-style mx-2'>
                      <img src={noProfileImage} alt="" />
                    </div>
                  </div> 
                  <div className='d-flex justify-content-start align-items-start mb-3' key={'answer'+index}>
                    <div className='icon-style mx-2 mt-1'>
                      <FontAwesomeIcon
                        className='genderIconAlignment'
                        color='white'
                        icon='comment-dots'
                        data-toggle="tool-tip"
                        title='Send'
                      />
                    </div>
                    {
                      
                      message?.answer && message?.answer !== 'loader' ?
                        <div className='message bot' dangerouslySetInnerHTML={{ __html: message.answer }}></div>
                    :
                      <div className='message bot'>
                        <TypingLoader />
                      </div>
                    }
                    
                  </div>
                  </>
              ))}
            </div>
           
          </> :
          <div className='new-chat'>
            <img src={configImages.dosystemsImg} alt="" />
          </div>
          }
        </div>
        <div className="chat-bot-input-wrapper">
          <div className="chatbot-input">
            <input
              type="text"
              value={input}
              placeholder={'Search Here ...'}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            />
            <button onClick={handleSend} disabled={disableButton}>
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='#fff'
                icon='paper-plane'
                data-toggle="tool-tip"
                title='Send'
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Search;
