const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  mydrives:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_mydrives_bulk_upload_file.csv",
  users:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_users_bulk_upload_file.csv",
  homes:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_homes_bulk_upload_file.csv",
  files:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_files_bulk_upload_file.csv",
  folders:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_folders_bulk_upload_file.csv",
  sharedfiles:
    "https://api.documentmanagementsystem.dosystemsinc.com/images/sample_sharedfiles_bulk_upload_file.csv",
};
export default configSampleFiles;
