import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ListGroup, ListGroupItem, Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faFileWord, faFilePdf, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

const MoveFilesModal = (props) => {
    const [initialFilesAndFolders, setInitialFilesAndFolders] = useState({ folders: [], files: [] });
    const [moveFilesResponse, setMoveFilesResponse] = useState({ folders: [], files: [] });
    const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
    const [folderId, setFolderId] = useState(null);
    const [destinationPath, setDestinationPath] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState([{ _id: 'home', name: 'Home' }]);
    const [tooltipOpen, setTooltipOpen] = useState({});
    const isHome = breadcrumb.length === 1 && breadcrumb[0].name === 'Home';

    useEffect(() => {
        getInitialFilesAndFoldersData();
    }, []);

    const getInitialFilesAndFoldersData = () => {
        fetchMethodRequest('GET', 'homes')
            .then(async (response) => {
                if (response) {
                    setInitialFilesAndFolders(response);
                    setMoveFilesResponse(response);
                    setFolderId(null);
                    setDestinationPath(null);
                    setBreadcrumb([{ _id: 'home', name: 'Home' }]);
                }
            })
            .catch((error) => {
                console.error('Error fetching initial data:', error);
            });
    };

    const moveFilesAndFolders = (folderId, destinationPath, item) => {
        const selectedRowsId = props.selectedRowsId;
        const fileToMove = selectedRowsId && selectedRowsId.length > 0 ? selectedRowsId[0] : null;
        let url;
        const body = {
            ...fileToMove,
            destParentFolderId: folderId,
            destPath: destinationPath
        };
        if (selectedRowsId && selectedRowsId[0] && selectedRowsId[0].type) {
            url = `files/fileMove/${props.moveFoldersId}`;
        } else {
            url = `folders/moveFolder/${props.moveFoldersId}`;
        }
        fetchMethodRequest("PUT", url, body)
            .then(async (res) => {
                if (res && res.respMessage) {
                    showToasterMessage(res.respMessage, 'success');
                    props.closeDeleteModal();
                    props.getDataFromServer();
                } else {
                    showToasterMessage(res.errorMessage, 'error');
                }
            })
            .catch((err) => {
                console.error("Fetch error:", err);
                showToasterMessage('Error moving files', 'error');
            });
    };

    const getFilesAndFoldersData = (item, filterCriteria) => {
        let url = 'homes';
        if (item && item._id && item.name !== 'Home') {
            filterCriteria['criteria'] = [{ key: "parentFolderId", value: item._id, type: 'eq' }];
            url = `homes?filter=${JSON.stringify(filterCriteria)}`
        }
        fetchMethodRequest('GET', url)
            .then(async (response) => {
                if (response) {
                    setMoveFilesResponse(response);
                    updateBreadcrumb(item);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const updateBreadcrumb = (item) => {
        if (item) {
            const existingIndex = breadcrumb.findIndex(b => b._id === item._id);
            if (existingIndex === -1) {
                const updatedBreadcrumb = [...breadcrumb, item];
                setBreadcrumb(updatedBreadcrumb);
            } else {
                const updatedBreadcrumb = breadcrumb.slice(0, existingIndex + 1);
                setBreadcrumb(updatedBreadcrumb);
            }
        } else {
            setBreadcrumb([{ _id: 'home', name: 'Home' }]);
            setMoveFilesResponse(initialFilesAndFolders);
        }
    };

    const handleFolderClick = (folderId, item) => {
        setFolderId(folderId);
        setDestinationPath(item.path);
        fetchMethodRequest('GET', `folders/${folderId}`)
            .then(async (response) => {
                if (response) {
                    if (breadcrumb.length === 0 || breadcrumb[breadcrumb.length - 1]._id !== item._id) {
                        updateBreadcrumb(item);
                    }
                    getFilesAndFoldersData(item, filterCriteria);
                }
            })
            .catch((error) => {
                console.error('Error fetching folder content:', error);
            });
    };

    const getFileIcon = (fileType) => {
        let icon = faFile;
        let color = 'gray';

        if (fileType === 'doc') {
            icon = faFileWord;
            color = 'rgb(18, 146, 238)';
        } else if (fileType === 'pdf') {
            icon = faFilePdf;
            color = 'red';
        }

        return { icon, color };
    };

    const renderBreadcrumb = () => {
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {breadcrumb.map((item, index) => (
                        <li key={item._id} className="breadcrumb-item" >
                            <a href="#" onClick={(event) => handleBreadcrumbClick(event, index)}>{item.name}</a>
                        </li>
                    ))}
                </ol>
            </nav>
        );
    };

    const renderFolderItems = () => {
        return moveFilesResponse.folders?.map((folder) => (
            (folder.name !== props.selectedRowsId[0]?.name || folder.path !== props.selectedRowsId[0]?.path ) && (
                <ListGroupItem
                    key={folder._id}
                    className='d-flex align-items-center move-folder-item getcursor'
                    onClick={() => handleFolderClick(folder._id, folder)}
                >
                    <div className="folder-name">
                        <FontAwesomeIcon icon={faFolder} className='folder-icon' />
                        {folder.name}
                    </div>
                    <Button
                        color="primary"
                        size="sm"
                        className="move-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            moveFilesAndFolders(folder._id, folder.path, folder);
                        }}
                    >
                        Move
                    </Button>
                </ListGroupItem>
            )
        ));
    };

    const renderFileItems = () => {
        return moveFilesResponse.files?.map((file) => (
            file.name !== props.selectedRowsId[0]?.name && (
                <ListGroupItem
                    key={file._id}
                    className='d-flex align-items-center move-folder-item disabled-file'
                    style={{ cursor: 'default' }}
                    id={`file-tooltip-${file._id}`}
                >
                    <div className="folder-name">
                        <FontAwesomeIcon icon={getFileIcon(file.type).icon} className='file-icon' style={{ color: getFileIcon(file.type).color }} />
                        {file.name}
                    </div>
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[`file-tooltip-${file._id}`]}
                        target={`file-tooltip-${file._id}`}
                        toggle={() => toggleTooltip(`file-tooltip-${file._id}`)}
                    >
                        Cannot select {file.name} because it is not a folder
                    </Tooltip>
                </ListGroupItem>
            )
        ));
    };

    const handleBreadcrumbClick = (event, index) => {
        event.preventDefault();

        const item = breadcrumb[index];

        if (item.name === 'Home') {
            setFolderId(null);
            setDestinationPath(null);
            setBreadcrumb([{ _id: 'home', name: 'Home' }]);
            setMoveFilesResponse(initialFilesAndFolders);
            return;
        }

        setFolderId(item._id);
        setDestinationPath(item.path);
        const updatedBreadcrumb = breadcrumb.slice(0, index + 1);
        setBreadcrumb(updatedBreadcrumb);
        getFilesAndFoldersData(item, filterCriteria);
    };

    const toggleTooltip = (id) => {
        setTooltipOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleBackClick = () => {
        if (breadcrumb.length > 1) {
            const previousItem = breadcrumb[breadcrumb.length - 2];
            setFolderId(previousItem._id);
            setDestinationPath(previousItem.path);
            const updatedBreadcrumb = breadcrumb.slice(0, breadcrumb.length - 1);
            setBreadcrumb(updatedBreadcrumb);
            getFilesAndFoldersData(previousItem, filterCriteria);
        }
    };

    return (
        <Modal isOpen={props.openMoveFolder} className='modal-dialog-centered align_Move_Modal' >
            <ModalBody className='align_Move_Modal_body'>
                <div className='modal-header'>
                    <div className='align_Move_Modal_header'>
                        {!isHome ?
                            <FontAwesomeIcon icon={faArrowLeft} onClick={handleBackClick} className='handle_back'/>
                            : null}
                        <h4 className='bold mb-0 move_button'>
                            {`Move ${props.selectedRowsId[0]?.name}`}
                        </h4>
                    </div>
                </div>
                <div>
                    <h5 className='current_location'><b>Current location : &nbsp; </b> {renderBreadcrumb()}</h5>
                </div>
                <div className="list-group-container">
                    {moveFilesResponse.folders.length === 0 && moveFilesResponse.files.length === 0 ? (
                        <div className="no-data">
                            <span className='no-data'>This Folder Is Empty</span>
                        </div>
                    ) : (
                        <ListGroup className="list-group">
                            {renderFolderItems()}
                            {renderFileItems()}
                        </ListGroup>
                    )}
                </div>
                <div className='modal-buttons cancel_button'>
                    <Button color="secondary" outline onClick={props.closeDeleteModal} >Cancel</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default MoveFilesModal;
