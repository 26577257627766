import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faFilePdf, faFileWord, faFile } from '@fortawesome/free-solid-svg-icons';
import 'react-circular-progressbar/dist/styles.css';

const Loader = ({ files, totalFiles, uploadedFiles, onClose }) => {
    const [successfulUploads, setSuccessfulUploads] = useState(0);

    const handleClose = (event) => {
        event.stopPropagation();
        onClose();
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
            return { icon: faFilePdf, color: 'red' };
        } else if (extension === 'docx') {
            return { icon: faFileWord, color: 'rgb(18, 146, 238)' };
        } else {
            return { icon: faFile, color: 'black' };
        }
    };

    useEffect(() => {
        // Count successful uploads
        const count = files.filter(file => file.progress === 100 && file.status === 'success').length;
        setSuccessfulUploads(count);
    }, [files]);

    return (
        <div className="loader-container">
            <div className="loader-header">
                <span className='bold'>
                    Uploads completed: {successfulUploads} / {totalFiles}
                </span>
                <button className="loader-close-button" onClick={handleClose}>×</button>
            </div>
            <div className="loader-status">
                <div className="uploaded-files-list">
                    {files.map((file, index) => {
                        const { icon, color } = getFileIcon(file.name);

                        const [showIcon, setShowIcon] = useState(false);

                        useEffect(() => {
                            if (file.progress === 100) {
                                const timer = setTimeout(() => {
                                    setShowIcon(true);
                                }, 500);
                                return () => clearTimeout(timer);
                            }
                        }, [file.progress]);

                        return (
                            <div key={index} className="uploaded-file-item">
                                <div className="file-info">
                                    <FontAwesomeIcon icon={icon} style={{ marginRight: '5px', color: color }} size="lg" />
                                    <span className="file-name">{file.name}</span>
                                </div>
                                <div className="circular-progress-wrapper circular_progress">
                                    <CircularProgressbar
                                        value={file.progress}
                                        styles={buildStyles({
                                            textSize: '8px',
                                            pathColor: file.progress === 100 ? (file.status === 'success' ? 'green' : 'red') : '#007bff',
                                            trailColor: '#d6d6d6',
                                        })}
                                    />
                                    {file.progress === 100 && showIcon && (
                                        <FontAwesomeIcon
                                            icon={file.status === 'success' ? faCheckCircle : faTimesCircle}
                                            className="check-icon loader_style"
                                            style={{color: file.status === 'success' ? 'green' : 'red'}}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Loader;
