import config from './config';
import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';

// To get logged-in user token
const getLoggedInUserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.loggedInUserData = {
            email: userData.email,
            password: userData.password,
        };
    }
    return headers;
};

const fetchMethodRequest = async (method, url, body, type, mul, extraBody) => {
    let headers = url === 'auth/login' ? {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    } : getLoggedInUserToken();

    if (type === 'upload') {
        const formData = new FormData();
        if (body && !mul) {
            formData.append('file', body);
        } else if (body && mul) {
            body.forEach(file => {
                formData.append('file', file);
            });
            if (extraBody) {
                Object.keys(extraBody).forEach(key => {
                    formData.append(key, extraBody[key]);
                });
            }
        }
        body = formData;
        delete headers['Content-Type'];
        delete headers.loggedInUserData;
    }

    return sendRequestToServer(method, url, body, headers)
        .then(response => {
            if (response.errorCode && response.errorCode === 9001) {
                // Handle token expiry
                return response;
            }
            return response;
        })
        .catch((err) => {
            console.error('Fetch error:', err);
            showToasterMessage(config.serverErrMessage, 'error');
            return 'serverError';
        });
};

const sendRequestToServer = async (method, url, body, headers) => {
    const reqHeaders = { ...headers };
    if (reqHeaders.loggedInUserData) {
        delete reqHeaders.loggedInUserData;
    }
    let isImageReqSent = false;
    let request;

    const options = {
        method,
        headers: reqHeaders,
    };

    if (body instanceof FormData) {
        delete options.headers['Content-Type'];
        options.body = body;
    } else if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(`${config.apiUrl}${url}`, options);
        const responseJson = await response.json();
        if (responseJson && responseJson.errorMessage === "Session expired please login again.") {
            localStorage.setItem('sessionexpired', true);
        }
        return responseJson;
    } catch (err) {
        showToasterMessage(config.serverErrMessage, 'error');
        return 'serverError';
    }
};

export default fetchMethodRequest;
