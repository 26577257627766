import React, { useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import apiCalls from "../../../config/apiCalls";
import JSZip from 'jszip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MultiFileUpload = (props) => {
    const folderInputRef = useRef(null);
    const [isFolderSelected, setIsFolderSelected] = useState(false);

    const setProgressBarResponse = (initialFiles, status) => {
        if(props.updateFileStatus){
            initialFiles.forEach((file, index) => props.updateFileStatus(index, 100, status));
        }
    }

    const handleSubmit = async () => {
        const files = folderInputRef.current.files;

        if (files.length === 0) {
            showToasterMessage('Please select a folder to upload.', 'error');
            return;
        }

        try {
            const initialFiles = Object.keys(files).map(key => ({
                name: files[key].name,
                progress: 0,
                status: 'uploading'
            }));
            if(props.updateFileProgress){
                props.updateFileProgress({ totalFiles: initialFiles.length, uploadedFiles: 0 })
            }
            if(props.showCurrentFilesInProgressBar){
                props.showCurrentFilesInProgressBar(true)
                if(props.setFilesToProgressBar){
                    props.setFilesToProgressBar(initialFiles)
                }
            }
            const zip = new JSZip();
            const filePromises = [];

            for (let file of files) {
                const relativePath = file.webkitRelativePath || file.name;
                const filePromise = file.arrayBuffer()
                    .then(fileContent => {
                        zip.file(relativePath, fileContent);
                    })
                    .catch(error => {
                        console.error('Error processing file:', error);
                        throw error;
                    });

                filePromises.push(filePromise);
            }
            await Promise.all(filePromises);
            const blob = await zip.generateAsync({ type: "blob" });
            const formData = new FormData();
            if (props?.assingValueToForm) {
                formData.append('file', blob, 'uploaded-folder.zip');
            }
            if (props?.assingValueToForm && props?.assingValueToForm?.path){
                formData.append('path', props.assingValueToForm.path);
            } if (props?.assingValueToForm && props?.assingValueToForm?.parentFolderId ) {
                formData.append('parentFolderId',props.assingValueToForm.parentFolderId);
            }
            else {
                formData.append('file', blob, 'uploaded-folder.zip');
            }
            let url = '';
            if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
                url = `${apiCalls.folderUplode}?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}?type=${'files'}`
            } else {
                url = `${apiCalls.folderUplode}`
            }

            fetchMethodRequest('POST', url, formData)
                .then(async (response) => {
                    if (response && response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        setProgressBarResponse(initialFiles,'success');
                        props.getDataFromServer();
                        props.closeDeleteModal();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        setProgressBarResponse(initialFiles,'error');
                    } else {
                        showToasterMessage('Upload failed');
                        setProgressBarResponse(initialFiles,'error');
                    }
                })
                .catch((err) => {
                    console.error('Fetch error:', err);
                    showToasterMessage('Upload failed');
                    setProgressBarResponse(initialFiles,'error');
                    return
                });

        } catch (error) {
            console.error('Error uploading folder:', error);
            showToasterMessage('Upload failed');
        }
    };

    const handleButtonClick = () => {
        folderInputRef.current.click();
    };

    const handleFileChange = () => {
        const files = folderInputRef.current.files;
        if (files.length > 0) {
            setIsFolderSelected(true);
            handleSubmit()
        } else {
            setIsFolderSelected(false);
        }
    };

    return (
        <div>
            <input
                type="file"
                ref={folderInputRef}
                webkitdirectory="true"
                mozdirectory="true"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}                           
                />
                <button type="button" onClick={handleButtonClick} className="custom-file-button" style={{ marginRight: 'auto' }} title={props?.title}>
                <FontAwesomeIcon
                    icon='folder'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" 
                    />
                </button>
        </div>
    );
}

export default MultiFileUpload;
