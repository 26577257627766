import React, { forwardRef, useEffect, useImperativeHandle, useState ,useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchMethodRequest from '../../../config/service';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const MenuBar = forwardRef(({ setMessages, chatId },ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const [chats ,setChats] = useState([])
  const navigate = useNavigate();
  let filterRef = useRef({"page":1,"limit":20,"sortfield":"created","direction" : "desc"})
  let chatsRef = useRef([])
  const [totalCount,setTotalCount] = useState(0)
  let previousScrollHeightRef = useRef()
  let scrollableRef = useRef()


  useImperativeHandle(ref, () => ({
    createChat(newChat) {
      getChats(newChat)
    }
  }));

  const getChats = (newChat = false , defaultFilter = null) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current
    fetchMethodRequest('GET',`chats?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
          if(newChat){
            navigate(`/search/${resp.chats[0]?._id}`)
          }
        }
      }
    })
  }

  useEffect(() => {
    getChats()
  },[])

  const navigateToChat = (chat) => {
    navigate(`/search/${chat._id}`)
  }

  const navigateToNewChat = () => {
    navigate('/search')
    setMessages([])
  }

  const debouncedGetChats = useCallback(
    _.debounce(() => {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      getChats(false,currentFilter)
    }, 100),
    []
  );

  const scrollToCurrentHeight = () => {
    if(scrollableRef.current) {
      scrollableRef.current.scrollTop =  previousScrollHeightRef.current - scrollableRef.current.scrollHeight ;
    }
  }

  const onScrollTop = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    if(bottom && (chats?.length < totalCount)) {
      debouncedGetChats()
    }
  }
  return (
    <div className='d-flex flex-column'>
    <div className='d-flex justify-content-between menu-buttons'>
          <button className="new-chat-button" onClick={() => setIsOpen(!isOpen)} >
            <FontAwesomeIcon icon="bars" />
          </button>
          {
            isOpen ?
            <button className="new-chat-button" onClick={() => navigateToNewChat()}
            >
              <FontAwesomeIcon icon="plus" />
              New Chat
            </button> : 
            <button className="new-chat-button" onClick={() => navigateToNewChat()}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          }
         
          
       </div>
    <div className="menu-bar chat-menubar" style={{ display: isOpen ? 'block' : 'none' }} onScroll={onScrollTop}>
      <ul className="message-list" ref={scrollableRef}>
        {
          chats && chats.length > 0 && chats.map((chat, index) => (
            <li key={index} onClick={() => navigateToChat(chat)} className={chatId === chat._id ? 'active-chat' : ''}>
              <div className='d-flex justify-content-between'>
              <div className='chat-name' title={chat.question}>{chat.question}</div> 
              <div className='active-chat-options'> <FontAwesomeIcon icon="ellipsis-h" /></div>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
    </div>
  );
});

MenuBar.propTypes = {
  messages: PropTypes.array.isRequired,
  onSelectMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onNewChat: PropTypes.func.isRequired,
};

export default MenuBar;
