import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Folders from './components/Folders';

const folders = (props,{ t }) => (
  <Container>
    <Folders {...props} />
  </Container>
);

folders.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(folders);
