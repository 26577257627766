import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoTextareaField from '../../Form/Fields/DoTextareaField';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'primereact/dialog';


const ShareModal = (props) => {
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [visible, setVisible] = useState(false);

    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
        watch
    } = useForm({
        // resolver: yupResolver(schema),
    });

    useEffect(() => {
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
        props?.sharedFilesResponse.map((user, i) =>{
            setValue(`permissions[${i}]`, user?.permissions ? user.permissions : props?.selectedRowsId[0]?.permissions)
        })
        
    }, []);
    const submit = (values) => {
        const { path, parentFolderId, size, name, type, _id, fileId, folderId } = props.selectedRowsId[0];
        let body;
        if (type) {
            body = {
                ...values,
                path,
                parentFolderId,
                size,
                name,
                type,
                fileId: props.type === 'SharedFiles' ? fileId._id : _id, 


            };
        } else {
            body = {
                ...values,
                path,
                parentFolderId,
                name,
                folderId: props.type === 'SharedFiles' ? folderId ._id: _id, 

            };
        }
        const url = `sharedFiles`;
        fetchMethodRequest("POST", url, body)
            .then(async (res) => {
                if (res && res.respMessage) {
                    showToasterMessage(res.respMessage, 'success');
                    props.closeDeleteModal();
                    props.getDataFromServer();
                } else {
                    showToasterMessage(res.errorMessage, 'error');
                }
            })
            .catch((err) => {
                console.error("Fetch error:", err);
            });
    };
   

    const handleDone = async () => {
        const values = getValues();
        const storedCredentials = localStorage.getItem('loginCredentials');
        const credentials = JSON.parse(storedCredentials);

        // Update the permissions in props.sharedFilesResponse based on the form values
        const updatedResponse = props.sharedFilesResponse.map((item, index) => {
            const updatedPermissions = values.permissions[index] || item.permissions;
            return {
                ...item,
                permissions: updatedPermissions,
                sharedWith: {
                    ...item.sharedWith,
                    // If needed, update sharedWith properties here
                }
            };
        });
        const requestBody = {
            updatedFiles: updatedResponse
        };

        // Send POST request with updated response array as the body
        try {
            const res = await fetchMethodRequest("POST", 'sharedFiles/updateSharedFiles', requestBody);
            if (res && res.respMessage) {
                showToasterMessage(res.respMessage, 'success');
                props.sharedFiles('','','', props.filterCriteria);
                setTimeout(() => {
                    props.closeDeleteModal();
                }, 100);
            } else {
                showToasterMessage(res.errorMessage, 'error');
            }
        } catch (err) {
            console.error("Fetch error:", err);
        }
    };



    const handlePermissionChange = (index, newPermission) => {
        const updatedUsers = props.selectedRowsId[0].sharedWith.map((user, i) => {
            if (i === index) {
                return {
                    ...user,
                    permissions: newPermission
                };
            }
            return user;
        });
    };



    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };



    const permissionsOptions = [
        { label: 'Viewer', value: 'View' },
        { label: 'Editor', value: 'Edit' }
    ];

    const toggleAdditionalFields = () => {
        setShowAdditionalFields(!showAdditionalFields);
        reset();
    };

    // Watch the autocomplete field to toggle the additional form fields
    const watchSharedWith = watch("sharedWith", []);

    useEffect(() => {
        if (watchSharedWith.length > 0) {
            setShowAdditionalFields(true);
        } else {
            setShowAdditionalFields(false);
        }
    }, [watchSharedWith]);
    return (
        <Dialog 
        className='screen'
            visible={props.openSharedModal}
            draggable={false}
            style={{ width: "30%" }}
            onHide={props.closeDeleteModal}
            closeOnEscape={false}
            focusOnShow={false}
            blockScroll={false}
            header={
                <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={showAdditionalFields ? faArrowLeft : ''} onClick={toggleAdditionalFields} style={{ cursor: 'pointer', marginRight: '10px' }} />
                    <h4>
                        {props.type === 'SharedFiles'
                            ? (props?.selectedRowsId[0]?.type
                                ? `Share ${props.selectedRowsId[0]?.fileId?.name.split('.').slice(0, -1).join('.')}`
                                : `Share ${props.selectedRowsId[0]?.folderId?.name}`)
                            : (props.displayName === 'Home' && props?.selectedRowsId[0]?.type
                                ? `Share ${props.selectedRowsId[0]?.name.split('.').slice(0, -1).join('.')}`
                                : `Share ${props.selectedRowsId[0]?.name}`)
                        }

                    </h4>

                </div>
            }                  
        >
            <ModalBody style={{ padding: '20px' }}>
                <form onSubmit={handleSubmit(submit)} autoComplete="off">
                    <div style={{ width: '100%' }}>
                        <div className="form-group">
                            <Controller
                                name="sharedWith"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoAutoCompleteField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        multiple={true}
                                        fieldState={fieldState}
                                        errors={errors}
                                        screen={props.type}
                                        searchApi="employees"
                                        searchField="email"
                                        placeholder="Add People"
                                        allow={props.allowDuplicates}
                                        getValues={getValues}
                                        watch={watch}
                                        setValue={setValue}
                                    />
                                )}
                            />
                        </div>
                        {showAdditionalFields ? (
                            <>
                                <div className="form-group">
                                    <Controller
                                        name="permissions"
                                        control={control}
                                        defaultValue={'View'}
                                        render={({ field, fieldState }) => (
                                            <DoSelectField
                                                input={field}
                                                id={field.id}
                                                name={field.name}
                                                field={field}
                                                fieldState={fieldState}
                                                errors={errors}
                                                // defaultValue={'Viewer'} 
                                                options={permissionsOptions}
                                                placeholder="Permission"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <Controller
                                        name="message"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoTextareaField
                                                input={field}
                                                id={field.id}
                                                name={field.name}
                                                field={field}
                                                fieldState={fieldState}
                                                errors={errors}
                                                placeholder="Message"
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        ) :
                            (
                                <div>
                                    <b className='addpeople d-flex'>People with access</b>
                                    <div className='addpeople'>
                                        {props?.sharedFilesResponse && props?.sharedFilesResponse.map((user, index) => (
                                            <div key={index} className="user-access d-flex align-items-center">
                                                <div className='user-circle' style={{ backgroundColor: getRandomColor() }}>
                                                    <span className='user-initial'>
                                                        {user?.sharedWith?.email ? user.sharedWith.email[0].toUpperCase() : ''}
                                                    </span>
                                                </div>
                                                <span className='user-email'>{user?.sharedWith.email}</span>
                                                {user?.permissions && user?.permissions === "Owner" ? (
                                                    <span className='user-permissions'>{user.permissions ? user.permissions : props?.selectedRowsId[0]?.permissions}</span>
                                                ) : (
                                                    <span className='user-permissions'>
                                                        <Controller
                                                            name={`permissions[${index}]`} // Unique name incorporating index
                                                            control={control}
                
                                                            render={({ field, fieldState }) => (
                                                                <DoSelectField
                                                                    input={field}
                                                                    id={`permissions[${index}]`}
                                                                    name={`permissions[${index}]`}
                                                                    field={field}
                                                                    fieldState={fieldState}
                                                                    errors={props.errors}
                                                                    options={permissionsOptions}
                                                                    placeholder="Permission"
                                                                    onChange={(e) => {
                                                                        handlePermissionChange(index, e.target.value);
                                                                    }}
                                                                />

                                                            )}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='modal-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button color="secondary" outline onClick={props.closeDeleteModal} style={{ marginRight: '10px' }}>Cancel</Button>
                        <Button color="primary" onClick={showAdditionalFields ? handleSubmit(submit) : handleDone}>{showAdditionalFields ? 'Send' : 'Done'}</Button>
                    </div>
                </form>
            </ModalBody>
        {/* // </Modal> */}
        </Dialog>
    );
};

export default ShareModal;
